import { createSlice } from '@reduxjs/toolkit';
import { buildAsyncReducers } from '../thunkTemplate';
import { resources as initialState } from '../initialState';

import {
  getOrganizationResources,
  putOrganizationResource,
  batchPutOrganizationResources,
} from './_resources';

// NOTE: "Mutating" state is safe in redux toolkit because it uses Immer
const { reducer, actions } = createSlice({
  name: 'resources',
  initialState,
  reducers: {
    clearResources: () => ({ ...initialState }),
  },
  extraReducers: (builder) => {
    buildAsyncReducers(builder, [
      getOrganizationResources,
      putOrganizationResource,
      batchPutOrganizationResources,
    ]);
  },
});

// Export the reducer, either as a default or named export
const { clearResources } = actions;
export {
  clearResources,
  getOrganizationResources,
  putOrganizationResource,
  batchPutOrganizationResources,
};
export default reducer;
