import { createAsyncThunk } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { get, isEqual } from 'lodash';

import { addToastr, types } from '../../store/toastr';
import {
  postOrganizationCredentialAPI,
  getOrganizationCredentialsAPI,
  putOrganizationCredentialAPI,
  deleteOrganizationCredentialAPI,
  pollOrganizationCredentialsAPI,
  getOrganizationCredentialStatusAPI,
  resyncEdgeCredentialEventsAPI,
} from '../../api';

const postOrganizationCredential = createAsyncThunk(
  'credentials/postOrganizationCredential',
  async ({ data }, { dispatch, getState, requestId }) => {
    try {
      const { currentRequestId, loading } = getState().credentials;
      const organization = getState().organization.default;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());
      let { credentials, added } = await postOrganizationCredentialAPI(
        organization.item_id,
        data
      );
      dispatch(
        addToastr({
          title: 'Credential added!',
          type: types.success,
          message: `Added ${added.name} for ${organization.name}.`,
        })
      );
      credentials = await _getOrganizationCredentialStatus(
        { data: added },
        credentials,
        dispatch
      );

      dispatch(getOrganizationCredentialStatus({ data: added }));

      return { credentials };
    } catch (err) {
      dispatch(
        addToastr({
          title: 'Failed to add Credential',
          type: types.error,
          message: get(err, 'response.data.reason', 'Bad Request'),
        })
      );
      console.error(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const getOrganizationCredentials = createAsyncThunk(
  'credentials/getOrganizationCredentials',
  async (org, { dispatch, getState, requestId }) => {
    const { currentRequestId, loading } = getState().credentials;
    const organization = org || getState().organization.default;

    if (
      isEqual(organization, {}) ||
      loading !== true ||
      requestId !== currentRequestId
    ) {
      return;
    }

    dispatch(showLoading());
    const credentials = await getOrganizationCredentialsAPI(
      organization.item_id
    );
    dispatch(hideLoading());

    return { credentials };
  }
);

const putOrganizationCredential = createAsyncThunk(
  'credentials/putOrganizationCredential',
  async (
    { data, refreshStatus = false },
    { dispatch, getState, requestId }
  ) => {
    try {
      const { currentRequestId, loading } = getState().credentials;
      const organization = getState().organization.default;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());
      let { credentials, updated } = await putOrganizationCredentialAPI(
        data.item_id,
        data
      );
      dispatch(
        addToastr({
          title: 'Credential updated!',
          type: types.success,
          message: `Updated ${updated.name} for ${organization.name}.`,
        })
      );

      if (refreshStatus) {
        credentials = await _getOrganizationCredentialStatus(
          { data: updated },
          credentials,
          dispatch
        );
      }

      return { credentials };
    } catch (err) {
      dispatch(
        addToastr({
          title: 'Failed to update Credential',
          type: types.error,
          message: get(err, 'response.data.reason', 'Bad Request'),
        })
      );
      console.error(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const deleteOrganizationCredential = createAsyncThunk(
  'credentials/deleteOrganizationCredential',
  async ({ data }, { dispatch, getState, requestId }) => {
    try {
      const { currentRequestId, loading } = getState().credentials;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());
      let credentials = await deleteOrganizationCredentialAPI(
        data.item_id,
        data.relation_id
      );
      dispatch(
        addToastr({
          title: 'Credential removed!',
          type: types.success,
          message: get(data, 'name'),
        })
      );

      return { credentials };
    } catch (err) {
      dispatch(
        addToastr({
          title: 'Failed to remove Credential',
          type: types.error,
          message: get(err, 'response.data.reason', 'Bad Request'),
        })
      );
      console.error(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const pollOrganizationCredentials = createAsyncThunk(
  'credentials/pollOrganizationCredentials',
  async (id, { dispatch, getState, requestId }) => {
    try {
      const { currentRequestId, loading } = getState().credentials;
      const { item_id } = getState().organization.default;
      const orgId = id || item_id;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());

      const credentials = await pollOrganizationCredentialsAPI(orgId);

      return { credentials };
    } catch (err) {
      dispatch(
        addToastr({
          title: 'Failed to poll Credentials',
          type: types.error,
          message: get(err, 'response.data.reason', 'Bad Request'),
        })
      );
      console.error(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

export const implementedGetStatusServices = ['fmx:'];

const _getOrganizationCredentialStatus = async (
  { data },
  initCreds,
  dispatch
) => {
  try {
    if (!implementedGetStatusServices.includes(data.service)) {
      return initCreds;
    }
    const { credentials, updated } = await getOrganizationCredentialStatusAPI(
      data.item_id,
      data.relation_id
    );
    const { name, enabled } = updated;
    dispatch(
      addToastr({
        title: 'Status check succeeded',
        type: enabled ? types.success : types.warning,
        message: `Credential "${name}" is ${enabled ? '' : 'not '}active.`,
      })
    );
    return credentials;
  } catch (err) {
    dispatch(
      addToastr({
        title: 'Failed to get Credential status',
        type: types.error,
        message: get(err, 'response.data.reason', 'Bad Request'),
      })
    );
    console.error(err);
    return initCreds;
  }
};

const getOrganizationCredentialStatus = createAsyncThunk(
  'credentials/getOrganizationCredentialStatus',
  async ({ data }, { dispatch, getState }) => {
    try {
      const { credentials: initCreds } = getState().credentials;

      // Skip check if not implemented for the credential's service
      if (!implementedGetStatusServices.includes(data.service)) {
        return { credentials: initCreds };
      }

      dispatch(showLoading());

      const credentials = await _getOrganizationCredentialStatus(
        {
          data,
        },
        initCreds,
        dispatch
      );

      return { credentials };
    } catch (err) {
      dispatch(
        addToastr({
          title: 'Failed to get Credential status',
          type: types.error,
          message: get(err, 'response.data.reason', 'Bad Request'),
        })
      );
      console.error(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const resyncEdgeCredentialEvents = createAsyncThunk(
  'credentials/resyncEdgeCredentialEvents',
  async (credentialId, { dispatch, getState, requestId }) => {
    try {
      const { currentRequestId, loading } = getState().credentials;
      const { item_id: orgId } = getState().organization.default;
      const credName = getState().credentials.credentials.find(
        (cred) => cred.relation_id === credentialId
      ).name;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());

      await resyncEdgeCredentialEventsAPI(orgId, credentialId);
      dispatch(
        addToastr({
          title: 'Resyncing Edge Events',
          type: types.success,
          message: `Starting resync of events for ${credName}.`,
        })
      );
      return {};
    } catch (err) {
      dispatch(
        addToastr({
          title: 'Failed to resync edge events',
          type: types.error,
          message: get(err, 'response.data.reason', 'Bad Request'),
        })
      );
      console.error(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

export {
  postOrganizationCredential,
  getOrganizationCredentials,
  putOrganizationCredential,
  deleteOrganizationCredential,
  pollOrganizationCredentials,
  getOrganizationCredentialStatus,
  resyncEdgeCredentialEvents,
};
