import { createAsyncThunk } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { isEqual, get } from 'lodash';

import { addToastr, types } from '../../store/toastr';
import {
  getOrganizationDetailsAPI,
  putOrganizationResourceAPI,
  batchPutOrganizationResourcesAPI,
} from '../../api';

export const getOrganizationResources = createAsyncThunk(
  'resources/getOrganizationResources',
  async (org, { dispatch, getState, requestId }) => {
    const { currentRequestId, loading } = getState().resources;
    const organization = org || getState().organization.default;

    if (
      isEqual(organization, {}) ||
      loading !== true ||
      requestId !== currentRequestId
    ) {
      return;
    }

    dispatch(showLoading());

    const { resources } = await getOrganizationDetailsAPI(
      organization.item_id,
      'resources',
      true
    );

    dispatch(hideLoading());

    return { resources };
  }
);

export const putOrganizationResource = createAsyncThunk(
  'resources/putOrganizationResource',
  async ({ data }, { dispatch, getState, requestId }) => {
    try {
      const {
        currentRequestId,
        loading,
        resources: prevResources,
      } = getState().resources;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());

      const [orgId, credId] = data.relation_id.split('#');

      const updated = await putOrganizationResourceAPI(orgId, credId, data);
      dispatch(
        addToastr({
          title: 'Resource updated!',
          type: types.success,
          message: `Updated ${updated.name}.`,
        })
      );

      const resources = prevResources.map((res) =>
        res.item_id === data.item_id && res.relation_id === data.relation_id
          ? updated
          : res
      );

      return { resources };
    } catch (err) {
      dispatch(
        addToastr({
          title: 'Failed to update Resource',
          type: types.error,
          message: get(err, 'response.data.reason', 'Bad Request'),
        })
      );
      console.error(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

export const batchPutOrganizationResources = createAsyncThunk(
  'resources/batchPutOrganizationResources',
  async ({ resource_updates }, { dispatch, getState, requestId }) => {
    try {
      const {
        currentRequestId,
        loading,
        resources: initialResources,
      } = getState().resources;

      const org_id = getState().organization.default.item_id;
      const orgName = getState().organization.default.name;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());
      const updated = await batchPutOrganizationResourcesAPI(
        org_id,
        resource_updates
      );
      dispatch(
        addToastr({
          title: 'Success!',
          type: types.success,
          message: `Updated ${updated.length} ${
            updated.length > 1 ? 'resources' : 'resource'
          } for ${orgName}.`,
        })
      );

      const updatedIds = updated.map(
        (resource) => `${resource.item_id}|${resource.relation_id}`
      );
      const unchangedResources = initialResources.filter(
        (resource) =>
          !updatedIds.includes(`${resource.item_id}|${resource.relation_id}`)
      );
      const resources = [...unchangedResources, ...updated];

      return { resources };
    } catch (err) {
      console.error(err);
      dispatch(
        addToastr({
          title: 'Failed to update Resources',
          type: types.error,
          message: get(err, 'response.data.reason', 'Bad Request'),
        })
      );
    } finally {
      dispatch(hideLoading());
    }
  }
);
